import { ReactNode } from 'react';
import { UserPermission, useAuth } from '../../hooks/AuthContext';

export default function CheckAuth({
  children,
  adminPermission,
  type,
  clientPermission,
  clientAccountType,
}: CheckAuthProps) {
  const { user, hasPermission, loginStatus } = useAuth();

  if (loginStatus != 'LOGGED_IN' || !user) return null;

  if (type && !type.includes(user.type as any)) {
    return null;
  }

  if (user.type == 'admin' && adminPermission) {
    if (
      Array.isArray(adminPermission) &&
      !adminPermission.some(permission => hasPermission(permission)) &&
      !user.permissions.includes('superadmin')
    ) {
      return null;
    }

    if (
      !Array.isArray(adminPermission) &&
      !hasPermission(adminPermission) &&
      !user.permissions.includes('superadmin')
    ) {
      return null;
    }
  } else if (user.type == 'client') {
    if (clientPermission) {
      if (!user.role) {
        return null;
      }

      if (Array.isArray(clientPermission)) {
        for (const permission of clientPermission) {
          if (!user.role[permission]) {
            return null;
          }
        }
      } else if (!user.role[clientPermission]) {
        return null;
      }
    }

    if (
      clientAccountType &&
      clientAccountType.length &&
      !clientAccountType.includes(user.accountType)
    ) {
      return null;
    }
  }

  return children;
}

interface CheckAuthProps {
  children: ReactNode;
  type?: UserType[] | UserType;
  adminPermission?: UserPermission[] | UserPermission;
  clientPermission?: ClientPermission[] | ClientPermission;
  clientAccountType?: ('PROCURA' | 'SAAS')[];
}

type UserType = 'admin' | 'vendor' | 'client';
export type ClientPermission =
  | 'canCreateOrder'
  | 'canCreateOrderRequest'
  | 'canViewAllAddresses';
