import { useRouter } from 'next/router';
import { ReactElement, createContext, useEffect } from 'react';
import { useContext, useState } from 'react';

const PageTitleContext = createContext<{
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void;
  icon: ReactElement;
  setIcon: (icon: ReactElement) => void;
  title: string;
  setTitle: (title: string) => void;
}>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  icon: null,
  setIcon: () => {},
  title: '',
  setTitle: () => {},
});

export function PageTitleProvider({ children }) {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [icon, setIcon] = useState<ReactElement>(null);
  const [title, setTitle] = useState<string>('');

  return (
    <PageTitleContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
        icon,
        setIcon,
        title,
        setTitle,
      }}
    >
      {children}
    </PageTitleContext.Provider>
  );
}

export function usePageTitle({
  path = undefined,
  icon = undefined,
  title = undefined,
}: {
  path?: BreadcrumbItem[];
  icon?: ReactElement;
  title?: string;
} = {}) {
  const ctx = useContext(PageTitleContext);
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    ctx.setBreadcrumbs(path == undefined ? [] : path);
    ctx.setIcon(icon);
    ctx.setTitle(title || '');
  }, [router.asPath]);

  return ctx;
}

export interface BreadcrumbItem {
  label: string;
  href: string;
}
