import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDqUMdNs7iz2EKCfTP5_jAAKiZahjIyvIM',
  authDomain: 'procura-dashboard.firebaseapp.com',
  projectId: 'procura-dashboard',
  storageBucket: 'procura-dashboard.appspot.com',
  messagingSenderId: '35502671478',
  appId: '1:35502671478:web:d1fcd1b205903df3dc4157',
  measurementId: 'G-YPS7VSBY2T',
};

const initFirebaseNotifications = async () => {
  try {
    const app = initializeApp(firebaseConfig);
    const supported = await isSupported();

    if (!supported) {
      return null;
    }

    const messaging = getMessaging(app);

    const status = await Notification.requestPermission();

    if (status && status === 'granted') {
      const fcm_token = await getToken(messaging, {
        vapidKey:
          'BG-i1_23fITGRHlbp3VpHxoFMv5_Un1RqNbaCMsqOJ9a8xgX5vWfqHnxnMxiNaKli2Ho7YzZBDV3I7cEBpw-HHY',
      });

      if (fcm_token) {
        localStorage.setItem('fcm_token', fcm_token);
        return fcm_token;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export default initFirebaseNotifications;
