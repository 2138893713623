import { createContext, useContext, useState } from 'react';
import { io } from 'socket.io-client';

const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;
const ChatContext = createContext<ChatContextData>(null);

export const useChat = (): ChatContextData => {
  return useContext(ChatContext);
};

let socket = null;
const messageListeners: MessageListener[] = [];

export const ChatProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [currentConversation, setCurrentConversation] = useState<any>(null);

  const namespace = () => {
    if (NODE_ENV === 'development') {
      return 'https://connect-api-dev.procurabusiness.com';
    } else if (NODE_ENV === 'production') {
      return 'https://connect-api.procurabusiness.com';
    } else {
      return 'http://localhost:3000/';
    }
  };

  const connect = () => {
    if (socket) return;

    socket = io(namespace(), {
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem('procura_access_token')}`,
      },
    });

    socket.on('connect', () => {
      console.log('connected');
    });

    socket.on('connect_error', error => {
      console.error(error);
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    socket.on('error', error => {
      console.error(error);
    });

    socket.on('chat', message => {
      console.log('message', message);
      console.log(messageListeners.length);
      messageListeners.forEach(listener => {
        console.log('listener', listener.conversationId);
        if (listener.conversationId === message.conversationId) {
          listener.callback(message);
        }
      });
    });
  };

  const sendMessage = (
    conversationId: string,
    type: ChatConversationType,
    message: string,
  ) => {
    socket.emit('chat', {
      conversationId,
      type,
      message,
    });
  };

  const disconnect = () => {
    socket.disconnect();
    socket = null;
  };

  const addMessageListener = (listener: MessageListener) => {
    messageListeners.push(listener);
  };

  const removeMessageListener = (listener: MessageListener) => {
    const index = messageListeners.indexOf(listener);

    if (index > -1) {
      messageListeners.splice(index, 1);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        connect,
        disconnect,
        sendMessage,
        addMessageListener,
        removeMessageListener,
        selectedTab,
        setSelectedTab,
        currentConversation,
        setCurrentConversation,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

interface ChatContextData {
  connect: () => void;
  disconnect: () => void;
  sendMessage: (
    conversationId: string,
    type: ChatConversationType,
    message: string,
  ) => void;
  addMessageListener: (listener: MessageListener) => void;
  removeMessageListener: (listener: MessageListener) => void;
  selectedTab: number;
  setSelectedTab: (tabIndex: number) => void;
  currentConversation: any;
  setCurrentConversation: (conversation: any) => void;
}

type MessageListenerCallback = (message: {
  conversationId: string;
  type: ChatConversationType;
  message: string;
}) => void;

export interface MessageListener {
  callback: MessageListenerCallback;
  conversationId: string;
}

type ChatConversationType = 'direct' | 'order' | 'request';
