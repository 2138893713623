import axiosClient from '../utils/axios';

export const listNotifications = async () => {
  const res = await axiosClient.get('/notification/list');
  return res.data;
};

export const setNotificationLastSeen = async () => {
  const res = await axiosClient.post(`/notification/last-seen`);
  return res.data;
};

export const getNotificationLastSeen = async () => {
  const res = await axiosClient.get(`/notification/last-seen`);
  return res.data;
};
