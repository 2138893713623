import axiosClient from '../utils/axios';

export const listChatMessages = async (
  type: ChatType,
  conversationId: any,
  params: any = {},
) => {
  const { data } = await axiosClient.get(
    `/chat/${type}/messages/list/${conversationId}`,
    {
      params,
    },
  );

  return data;
};

export const listChatParticipants = async (
  type: ChatType,
  conversationId: any,
) => {
  const { data } = await axiosClient.get(
    `/chat/${type}/participants/list/${conversationId}`,
  );

  return data;
};

export const sendChatMessage = async (type: ChatType, data) => {
  await axiosClient.post(`/chat/${type}/messages/send`, data);
};

export const listChatConversations = async (type: ChatType) => {
  const { data } = await axiosClient.get(`/chat/${type}/conversation/list`);

  return data;
};

export const suggestNewConversation = async (type: ChatType) => {
  const { data } = await axiosClient.get(
    `/chat/${type}/conversation/suggest-new`,
  );

  return data;
};

export const createConversation = async (type: ChatType, userId: string) => {
  await axiosClient.post(
    `/chat/${type}/conversation/create?Id=${userId}`,
    null,
    {
      params: {
        userId,
      },
    },
  );
};

export type ChatType = 'order' | 'request' | 'direct';
