import { createContext, useContext, useEffect } from 'react';

const MetadataContext = createContext(null);

export const useMetadata = (): any => {
  return useContext(MetadataContext);
};

export const MetadataProvider = ({ children }) => {
  const refetchMetadata = async () => {};

  useEffect(() => {
    refetchMetadata();
  }, []);

  return (
    <MetadataContext.Provider value={{}}>{children}</MetadataContext.Provider>
  );
};
