import axiosClient from '../utils/axios';

export const listVendors = async (params: any) => {
  const res = await axiosClient.get('/vendor/list', {
    params,
  });
  return res.data;
};

export const sendVendorInvite = async (data: any) => {
  const res = await axiosClient.post('/vendor/create', data);
  return res.data;
};

export const checkVendorActivationToken = async (token: string) => {
  const res = await axiosClient.post(`/vendor/check-activation-token`, {
    activationToken: token,
  });
  return res.data;
};

export const activateVendor = async (data: any) => {
  const res = await axiosClient.post('/vendor/activate', data);
  return res.data;
};

export const revokeVendorInvitation = async (id: any) => {
  const res = await axiosClient.patch(`/vendor/revoke-invitation/${id}`);
  return res.data;
};

export const acceptOrRejectVendorInvitation = async (
  id: string,
  action: 'accept' | 'reject',
) => {
  const res = await axiosClient.patch(`/vendor/invitation/${action}?id=${id}`);
  return res.data;
};

export const listAllVendorProducts = async () => {
  const res = await axiosClient.get('/vendor/approved-products/list');
  return res.data;
};

export const listVendorProducts = async (clientOrVendorId: string) => {
  const res = await axiosClient.get(
    `/vendor/approved-products/list/${clientOrVendorId}`,
  );
  return res.data;
};

export const addVendorProduct = async (data: any) => {
  const res = await axiosClient.post(`/vendor/approved-products/add`, data);
  return res.data;
};

export const updateVendorProduct = async (data: any) => {
  const res = await axiosClient.patch(`/vendor/approved-products/update`, data);
  return res.data;
};

export const removeVendorProduct = async (data: any) => {
  const res = await axiosClient.delete(`/vendor/approved-products/remove`, {
    data,
  });
  return res.data;
};
