import { useToast } from '@chakra-ui/react';

export default function useSmartToast(): SmartToastProps {
  const toast = useToast();

  const success = (description: string) => {
    toast({
      title: 'Success',
      description,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const error = (error, defaultMessage: string) => {
    toast({
      title: 'Error',
      description:
        error?.response?.data?.message ||
        defaultMessage ||
        'An error occurred.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const info = (message: string) => {
    toast({
      title: 'Info',
      description: message,
      status: 'info',
      duration: 5000,
      isClosable: true,
    });
  };

  return {
    success,
    error,
    info,
  };
}

interface SmartToastProps {
  success: (message: string) => void;
  error: (error, defaultMessage: string) => void;
  info: (message: string) => void;
}
