import { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import {
  HStack,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FiSend } from 'react-icons/fi';
import { listChatParticipants } from '@/controllers/chat.controller';
import { useChat } from '@/hooks/ChatContext';

const MentionComponent = Mention as any;
const MentionsInputComponent = MentionsInput as any;

export default function ChatInput({
  inputMessage,
  setInputMessage,
  handleSendMessage,
}) {
  const { currentConversation } = useChat();
  const [participants, setParticipants] = useState([]);

  const getInputHeight = () => {
    const lines = Math.min(inputMessage.split('\n').length, 3);
    return lines * 1.5 + 1.5 + 'em';
  };

  const fetchParticipants = async () => {
    listChatParticipants(currentConversation.type, currentConversation.id).then(
      data => {
        setParticipants(data);
      },
    );
  };

  useEffect(() => {
    if (!currentConversation) return;

    fetchParticipants();
  }, [currentConversation]);

  return (
    <HStack
      px='4'
      mt='auto'
      pt='2'
      pb='4'
      as='form'
      onKeyDown={e => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleSendMessage();
        }
      }}
      onSubmit={e => {
        e.preventDefault();
        handleSendMessage();
      }}
    >
      <InputGroup rounded='full'>
        <MentionsInputComponent
          placeholder='Type a message'
          value={inputMessage}
          className='mentions-input'
          onChange={e => setInputMessage(e.target.value)}
          style={{
            minHeight: getInputHeight(),
            height: getInputHeight(),
          }}
        >
          <MentionComponent
            trigger='@'
            data={participants.map(p => ({ id: p.id, display: p.name }))}
          />
        </MentionsInputComponent>
        <InputRightElement>
          <IconButton
            aria-label='Send message'
            icon={<FiSend />}
            size='sm'
            colorScheme='gray'
            variant='ghost'
            color='gray.500'
            type='submit'
          />
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
}
